.multi-select-variable-editor {
  width: 100%;
  .select__control {
    border-radius: 3px;
    box-shadow: inset 0px -2px 0px rgba(212, 219, 228, 0.5);
    border-color: #d9dee4;
    cursor: pointer;
    width: 100%;

    .select__menu {
      margin-top: 0px;
    }

    &.select__control--menu-is-open,
    &:hover {
      color: #2b3542;
      background-color: #e6e6e6;
      border-color: #b5bfcb;
    }

    &:focus {
      box-shadow: none;
    }

    .select__indicator-separator {
      display: none;
    }

    .select__control {
      border-color: #d9dee4;
    }
    &:hover {
      border-color: #d9dee4;
    }

    .select__value-container {
      padding: 6px 5px;

      .select__multi-value {
        background-color: #edeff0;
      }

      .select__multi-value__remove {
        color: #252f3d;
      }
    }

    .select__indicators {
      svg {
        margin-bottom: 8px;
        margin-top: 8px;
        margin-right: 8px;
      }
    }
  }
}
