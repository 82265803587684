/*
  PAGE : DEALS
*/

.page-deals {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 100%;
  max-width: 100%;
  flex: 1;
  min-height: calc(100vh - #{$top-bar-height});

  .title-bar {
    h1 {
      max-width: 50%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    h1 span {
      color: $black40;
    }
  }

  .contracts {
    width: 100%;
    margin: 0 auto;
    padding: 0;
    position: relative;

    .admin-page-body {
      margin-bottom: 150px;
      .panel-tabs {
        margin-bottom: 20px;
      }
      .admin-tab-header {
        .admin-search-users,
        .admin-search-teams {
          width: 350px;
        }

        .users-list {
          .rt-td.col-actions {
            overflow: visible;
            text-align: center;
            .dropdown-menu {
              z-index: 10;
            }
          }
        }

        .teamsCount,
        .themesCount {
          cursor: default;
        }

        .apiKey {
          svg {
            vertical-align: middle;
            path {
              fill: $black60;
            }
          }
        }

        .name {
          .disabled {
            color: #8795a5;
            cursor: default;
          }
          .enabled {
            cursor: pointer;
          }
        }

        .connect,
        .batch {
          display: flex;
          align-items: center;
        }

        .tag {
          display: inline-block;
          padding: 5px 10px;
          font-size: 9px;
          font-weight: 700;
          line-height: 9px;
          text-transform: uppercase;
          border-radius: 2px;
          border-color: transparent;
          position: relative;
          border-width: 1px;
          margin-left: 10px;
          &.admin {
            background-color: $primary-light;
            color: $outlaw;
          }
          &.partner {
            background-color: rgb(209, 250, 232);
            color: #46ca8c;
          }
          &.admin-page-tag {
            margin-left: 2px !important;
          }
        }
      }
    }

    @include mediaquery-medium {
      width: calc(100% - #{$sidebar-width});
      margin-left: 60px;
      padding: 0 60px;
      // If we got extra columns, remove the max-width for readability
      &.costum-columns {
        max-width: initial;
      }
    }

    @include mediaquery-xlarge {
      width: calc(100% - #{$sidebar-width} - #{$deal-filters-width});
      margin-left: auto;
      max-width: 1450px;
      // If we got extra columns, remove the max-width for readability
      &.costum-columns {
        max-width: initial;
      }
    }
  }

  .alerts-wrapper {
    .dmp-alert {
      margin-bottom: 10px;
    }
  }

  .deal-listing {
    .ReactTable {
      min-height: 60px;
    }

    //.parties column style tweaks
    .parties {
      .party-item {
        &:not(:first-child) {
          // add margin-top to every party-item except the first-child
          margin-top: 10px;
        }
      }
      .user-or-party-subtitle {
        color: $black40;
      }
    }

    .rt-td.col-actions {
      overflow: visible;
      text-align: center;
      background-color: white;

      .deal-edit-dropdown {
        z-index: 10;
      }
    }

    .rt-tr {
      // Now that we've got custom workflows, long step labels can be clipped inside the status column
      // They're already stylized as colorLabels though, so this avoids a wierd ellipsis after it
      a.status {
        text-overflow: clip;
        overflow: hidden;
      }
      .rt-td.origin {
        .origin-icon {
          text-align: center;
          // Set it back to left-align on Desktop so that it does not look weird (since the column will be larger)
          @include mediaquery-medium {
            text-align: initial;
          }
          svg {
            vertical-align: middle;
            path {
              fill: $black60;
            }
          }
          .dmp-loader {
            display: inline-block;
            vertical-align: middle;
          }
        }
      }

      .deal-name {
        .ai-status-icon,
        .bundle-icon,
        .trash-icon {
          display: inline-block;
          svg {
            margin-top: -2px;
            vertical-align: middle;
          }
          svg:hover {
            path {
              fill: #2b3542;
            }
          }
          .dmp-loader {
            display: inline-block;
            vertical-align: middle;
          }
        }
        .dmp-loader {
          display: inline-block;
          vertical-align: middle;
          margin-right: 5px;
          margin-top: -2px;
          .dmp-loader-spinner {
            width: 10.5px;
            height: 10.5px;
          }
        }
      }

      .bundle-icon {
        margin-left: 5px;
      }

      .ai-status-icon,
      .trash-icon {
        margin-right: 5px;
      }

      .grade {
        .grade-cell {
          position: relative;
          display: block;
          min-width: 30px;
          max-width: 60px;
          overflow: hidden;
          text-overflow: ellipsis;
          border-radius: 2px;
          letter-spacing: 0.3px;
          line-height: initial;
          font-size: 0.8rem;
          text-transform: uppercase;
          font-weight: 700;
          text-align: center;
          color: $gray-1;
          border: 1px solid $gray-1;
        }
      }

      // For any (custom) variables displayed in table, we want to display the full text
      // so this overrides the ellipsis
      .rt-td.variable {
        white-space: inherit;
      }
    }

    .deal-tags {
      display: flex;
      flex-wrap: wrap;

      span {
        @extend .dealTag;
        margin-top: 5px;

        &:not(:last-child) {
          margin-right: 3px;
        }
      }
    }
  }

  // top Results
  .top-of-results {
    min-height: 30px;
    .results-and-tags {
      display: flex;
      align-items: center;

      .results {
        display: inline;
        // Align with the tags that can stack on the right
        min-height: 30px;
        line-height: 30px;
      }

      .tag-wrapper {
        display: inline;
        padding-top: 6px;
      }

      @include mediaquery-medium {
        display: flex;
        flex: 1;
        align-items: flex-start;
        flex-direction: row;
      }
    }
    .restore {
      line-height: 28px;
      padding: 0px 0px;
      font-size: 1.2rem;
    }

    .text-danger a span {
      color: $color-red;
    }
  }

  /*
    Deal Filters are currently hidden on Mobile due to the complexity it
    brings to the interface.
  */
}

.popover-lens-filter {
  .close-popover {
    top: 20px !important;
  }

  .question {
    margin-top: -5px;
    margin-bottom: 5px;
  }

  .actions {
    margin-top: 10px;
    display: flex !important;
    .dmp-btn-link {
      margin-left: auto;
    }
  }
}

/*
  Variable filter popover
*/
.popover-lens-filter,
.popover.popover-deal-var-filter {
  width: 250px;
  max-width: 250px;

  .popover-title {
    padding: 15px 45px 0px 15px !important;
  }

  .popover-lens-label {
    font-size: 1.2rem;
    flex: 1;
    padding-right: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .lens-check-pass-fail {
    margin-top: 10px;
  }

  .variable-options {
    opacity: 1;
    margin-bottom: 10px;
    max-height: 200px;
    overflow-y: scroll;

    &.state .#{$dmp-checkbox} {
      width: 100%;
    }

    .#{$dmp-checkbox} {
      margin-bottom: 4px;
      width: 100%;
      margin-right: 0;
    }
  }

  .configuring {
    .actions {
      text-align: right;
    }

    .values {
      display: flex;
      align-items: center;
      margin-bottom: 8px;

      .and {
        padding: 0 5px;
      }

      input {
        flex: 1;
        margin-bottom: 0;
      }
    }

    .dmp-alert {
      margin-bottom: 8px;
      border-radius: 5px;
    }
  }
}

.popover.popover-deal-date-filter {
  width: 250px;
  max-width: 250px;

  .popover-title {
    padding: 15px 45px 6px 15px !important;
  }

  //this is for extending the picker for single dates in date filter.
  .dmp-daytime-container-single {
    .InputFromTo {
      .DayPickerInput {
        input,
        .react-time-picker__wrapper {
          width: 218px !important;
        }
      }
    }
  }

  input:focus {
    outline: none !important;
    border: 1px solid #8c98a5;
    box-sizing: border-box;
    border-radius: 3px;
  }

  .and {
    padding: 0 5px;
  }
}

.popover.popover-deal-sharing-status-filter {
  .close-popover {
    top: 20px;
  }
}

/*
  Deal Edit Dropdown custom Popover
*/
.popover-edit-deal {
  position: absolute;
  margin-left: -65px;
  margin-top: -25px;
  z-index: 1000;

  .dropdown-menu {
    display: block;
    position: initial;

    .text-danger a {
      color: $color-red;
    }
  }
}
