//TODO: turn into a DMP component
//For now, putting at top-level for use on teams page
.panel-tabs {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  height: 40px;
  border-bottom: 1px solid $black10;

  .dmp-btn-link {
    font-weight: 600;
    font-size: 1.2rem;
    padding-left: 15px;
    padding-right: 15px;
    color: $black100 !important;
    text-decoration: none !important;

    &.dmp-btn-large {
      font-size: 1.4rem;
    }

    &:hover:not(.active) {
      color: $black60 !important;
    }

    &.active::after {
      content: '';
      position: absolute;
      height: 3px;
      right: 0px;
      left: 0px;
      bottom: -2px;
      background: $black100;
    }
  }
}

/*
  DEAL : DEAL PANEL
  This also includes specific panel styling (for now)
*/

.deal-panel {
  //on mobile, panels sit over everything except top bar
  &.bottom {
    margin: 0;
  } //learn why this needs to be reset (app_init.scss is issue)

  z-index: $layer-activity;
  right: 0;
  top: calc(#{$mobile-nav-height} + 1px);
  min-width: 100vw;
  width: 100vw;
  position: absolute;
  padding: 0px;
  margin: 0;
  border: 0;
  background-color: $black2;

  @include mediaquery-medium {
    &.ai-blocks {
      width: 520px;
      min-width: 520px;

      > .popover-content {
        width: 520px;
      }
    }
    min-width: $panel-desktop-width;
    width: $panel-desktop-width;
  }

  // These '>'s are important to only target the direct descendents
  // And not collide with popovers that the panel may open internally *inside* the deal panels
  > .arrow {
    display: none;
  }
  > .popover-content {
    padding: 0;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;

    max-height: calc(100vh - #{$mobile-nav-height});
    min-height: calc(100vh - #{$mobile-nav-height});
    @include mediaquery-medium {
      max-height: calc(100vh - #{$top-bar-height-slim} - #{$deal-header-height});
      min-height: calc(100vh - #{$top-bar-height-slim} - #{$deal-header-height});
    }

    @include mediaquery-medium {
      width: $panel-desktop-width;
      border-left: 1px solid $black10;
      border-bottom: 1px solid $black10;
    }

    .panel-header {
      min-height: $panel-header-height;
      z-index: 10;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      padding: 0 15px;
      background-color: $white;
      border-bottom: 1px solid #d4dbe4;

      .panel-title {
        flex: 1;
        font-weight: 600;
        font-size: 1.2rem;
      }
      .panel-close {
        color: $black20;
        cursor: pointer;
        font-weight: 700;
        &:hover {
          color: $black60;
        }
      }
    }

    // Some panels have a secondary control-bar beneath panel-title
    .filter-bar {
      height: $panel-header-height;
      background: $white;
      border-bottom: 1px solid $black10;
      // panel-header has a 1px border on both top and bottom,
      // so a 1px transparent border here is necessary to make the true box size identical (40px)
      // without this, the ::after element that shows on active tabs is 1px higher (inconsistent)
      border-top: 1px solid transparent;

      .spacer {
        flex: 1;
      }
    }

    .panel-body {
      margin-bottom: 70px;
      flex: 1;
      z-index: 0;
      width: 100%;
      padding: 0px;
      display: flex;
      flex-direction: column;
      /* Important: https://medium.com/@stephenbunch/how-to-make-a-scrollable-container-with-dynamic-height-using-flexbox-5914a26ae336 */
      min-height: 0;

      .panel-scroll {
        overflow-y: auto;
        flex-grow: 1;
        /* Important: https://medium.com/@stephenbunch/how-to-make-a-scrollable-container-with-dynamic-height-using-flexbox-5914a26ae336 */
        min-height: 0;
        overflow-x: hidden;
        position: relative;
        display: block;
      }

      .property-row {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      .property-row-item {
        flex: 1;
        &:not(:last-child) {
          padding-right: 10px;
        }
      }

      .property-borders {
        display: flex;
        justify-content: space-between;

        .property-row-item {
          flex: initial;
        }
        span {
          margin-left: 5px;
        }
      }

      .item-label {
        font-size: 1.3rem;
        color: $black80;
        font-weight: 500;
        // Bold the label too if item-label is a dmp Checkbox
        &.dmp-checkbox label {
          font-weight: 500;
        }

        &.error {
          font-weight: bold;
          color: $color-red;
        }

        a:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
      .item-sub {
        color: $black40;
        font-size: 1.1rem;
        font-weight: 300;
      }

      .analysis-loading {
        display: flex;
        color: $gray-1;

        .dmp-alert {
          margin-bottom: 0px;
        }

        .dmp-loader {
          margin-right: 10px;
          vertical-align: middle;
          margin-top: 2px;
        }
      }

      .clause-issue-switch-container,
      .clause-diff-switch-container {
        display: flex;

        .issue-switch-title,
        .diff-switch-title {
          padding: 4px 0px;
          font-size: 12px;
          font-weight: 600;
          line-height: 15px;
          color: $gray-0;
        }

        .show-clause-issues {
          span {
            font-size: 12px;
            font-weight: 600;
            line-height: 15px;
          }
        }

        .show-clause-issues,
        .show-clause-diff {
          margin-left: auto;
        }
      }

      .clause-title {
        font-size: 12px;
        font-weight: 600;
        line-height: 15px;
        margin-bottom: 5px;
      }

      .clause-display {
        max-width: 300px;

        .clause-display-container {
          display: flex;
          .clause-title {
            font-size: 12px;
            font-weight: 600;
            line-height: 15px;
            margin-bottom: 5px;
          }
          .action-container {
            margin-left: auto;
            display: flex;

            .right {
              margin-left: 5px;
            }
          }
        }
        .clause-text-container {
          background-color: $gray-5;
          padding: 10px 10px 15px 15px;
          border-radius: 2px;
          border-left: 2px solid $gray-3;
          gap: 10px;

          .form-control {
            width: 270px;
            max-width: 270px;
            min-height: 300px;
            resize: vertical;
          }

          .edit-actions {
            display: flex;
            flex-direction: row-reverse;
          }

          &.failedCheck {
            background-color: #fce8e7;
            border-left: 2px solid #ee6662;
          }

          .clause-text {
            font-family: Courier;
            font-size: 12px;
            font-weight: 400;
            line-height: 15px;
            width: inherit;
            word-break: break-word;
          }
        }
      }

      .clause-comparison-header {
        display: flex;
        .compare-clause-title {
          font-size: 12px;
          font-weight: 600;
          line-height: 15px;
          color: $gray-0;
          padding: 4px 0px;
        }
      }

      .clause-summary-container {
        &.failedCheck {
          background: #fce8e7;
        }
        .clause-name {
          font-size: 14px;
          font-weight: 600;
          line-height: 20px;
          color: $gray-0;
          margin-bottom: 5px;

          &:hover {
            text-decoration: underline;
            cursor: pointer;
          }
        }
        .clause-question {
          font-size: 12px;
          line-height: 15px;
          margin-bottom: 5px;
        }

        .clause-answer {
          font-size: 12px;
          font-weight: 600;
          line-height: 15px;
          color: $gray-2;
          margin-bottom: 5px;
          height: 15px;

          &.failedCheck {
            color: #ee6662;
            svg {
              path {
                fill: #ee6662;
              }
            }
          }

          svg {
            margin-right: 5px;
            vertical-align: middle;
            margin-top: -2px;
            path {
              fill: $gray-2;
            }
          }
        }
      }

      .variable-view {
        &.manual-review,
        &.edit-extracted {
          .form-group {
            margin-bottom: 0px !important;
          }

          .mark-reviewed {
            padding: 5px 0px;
          }

          .cancel-edit {
            margin-left: 5px;
          }
        }
      }
      .definition {
        .term-definition-title {
          font-size: 12px;
          font-weight: 600;
          line-height: 15px;
          margin-bottom: 5px;
        }

        .term-info-container {
          width: 100%;
          display: flex;
          cursor: pointer;

          .dmp-btn-icon {
            margin-left: auto;
          }

          .variable-view {
            flex: 1;
          }

          .term-value {
            font-size: 12px;
            line-height: 15px;
            color: $gray-0;
            &.empty {
              color: $gray-3;
            }

            svg {
              margin-right: 5px;
              vertical-align: middle;
            }
          }
        }
      }

      .field {
        .field-key {
          margin-right: 5px;
          text-decoration: underline;
        }
        .field-value {
          font-weight: 600;
          color: $gray-2;
        }

        font-size: 12px;
        line-height: 15px;

        svg {
          margin-right: 5px;
          vertical-align: middle;
          margin-top: -2px;
        }
      }

      .terms-header-container {
        display: flex;
        flex-direction: row;
        .terms-count {
          font-size: 12px;
          font-weight: 600;
          line-height: 15px;
          align-self: flex-end;
          color: $gray-0;
        }
        .terms-filter {
          margin-left: auto;
          .terms-filter-switch {
            span {
              font-size: 12px;
              font-weight: 600;
              line-height: 15px;
            }
          }
        }
      }

      .summary-header {
        color: $gray-0;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        margin-bottom: 10px;
      }

      .summary-block {
        display: flex;
        flex-direction: row;
        font-size: 12px;
        font-weight: 600;
        line-height: 15px;
        margin-bottom: 10px;
        color: $gray-1;
      }

      .summary-sub-block {
        margin-left: auto;
        font-size: 12px;
        line-height: 15px;

        display: flex;
        .spacer {
          flex: 1;
        }
      }

      //Analysis
      .analysis-option-container {
        display: flex;
        flex-direction: row;
        margin-bottom: 10px;
        color: $gray-1;
        &.isDefault {
          color: $gray-3;
        }

        .icon-ai {
          align-self: flex-start;
          margin: 3px 5px 0 0;
          width: 15px;
          height: 15px;
          overflow: inherit;
          vertical-align: middle;

          &.isDefault {
            path {
              fill: $gray-3 !important;
            }
          }

          path {
            fill: $gray-1 !important;
          }
        }

        .analysis-summary {
          .summary-title {
            font-size: 12px;
            font-weight: 600;
            line-height: 15px;
            letter-spacing: 0px;
            text-align: left;
          }
          .summary-subtext {
            font-size: 12px;
            font-weight: 400;
            line-height: 15px;
            letter-spacing: 0px;
            text-align: left;
          }
        }

        .active {
          font-size: 12px;
          font-weight: 600;
          line-height: 15px;
          letter-spacing: 0px;
          text-align: left;

          color: #2dcb8d;
          margin-left: auto;
          &.isDefault {
            color: #dff7ee;
          }
        }
      }

      .property-title {
        font-weight: 600;
        color: $black80;
        margin-bottom: 5px;
      }
      .property-subtitle {
        font-size: 13px;
        font-weight: 500;
        color: $gray-0;
      }

      .footnote-number-scheme,
      .footnote-number-format {
        min-width: 140px;
        ul {
          min-width: 140px;
        }
      }

      .footnote-config-info {
        display: inline-block;
        svg {
          vertical-align: middle;
          margin-top: -2px;
        }
      }

      .property-info {
        color: $black40;
      }
    }

    // reusable style for empty deal panel info
    .empty {
      color: $black40;
      font-size: 1.2rem;
      width: 100%;
      display: block;
      padding: 5px 0;
    }
  }

  &.dotvine {
    > .popover-content {
      max-height: calc(100vh - #{$deal-header-height-vine});
      min-height: calc(100vh - #{$deal-header-height-vine});
      border-bottom: none;
    }
  }

  .deal-panel-item {
    &.deal-panel-item-disabled {
      .property-title,
      .property-info {
        color: $black40;
      }
    }
    .watermark-end {
      margin-bottom: 0px;
    }
  }

  &.integrations {
    .form-group {
      margin-bottom: 0;
    }
  }

  &.audit,
  &.elements-sidebar {
    .elements-list {
      flex: 1;
      display: flex;
      overflow: auto;
      flex-direction: column;
    }

    .filter-bar {
      svg {
        // .action-filter and .export icons
        cursor: pointer;
        width: 15px;
        height: 15px;
        path {
          fill: rgba($outlaw-dark, 1);
        }
      }

      .filter-group {
        height: $panel-header-height;
        display: flex;
        align-items: center;
        border-right: 1px solid $black10;
      }

      .filter-type {
        padding: 0 10px 0 5px;
        display: flex;
        align-items: center;

        .action-filter {
          //this is the button
          display: flex;
          align-items: center;
          justify-content: center;
          width: 35px;
          height: 35px;
          opacity: 0.25;
          &:hover {
            opacity: 0.5;
          }
          &.active {
            opacity: 1;
          }
          &.active:hover {
            opacity: 0.5;
          }
          svg path {
            fill: $outlaw-dark;
          }
        }
      }

      .filter-users {
        padding: 0 15px;
        flex: 1;
      }

      .export {
        display: flex;
        align-items: center;
        justify-content: center;
        height: inherit;
        width: $panel-header-height;
        span {
          display: inherit;
          align-items: inherit;
          justify-content: center;
          width: 35px;
          height: 35px;
        }
        &:hover {
          svg path {
            fill: rgba($outlaw-dark, 0.5);
          }
        }
      }
    }

    .deal-panel-item {
      position: relative;

      .day {
        .date {
          margin: 10px 0 5px 0;
          font-size: 1.4rem;
          line-height: 1.6;
          font-weight: 600;
          color: $black80;
        }
        .activity {
          padding-bottom: 15px;
          margin-bottom: 10px;
          position: relative;
          display: flex;
          flex-direction: row;
          border-bottom: 1px solid $black10;

          .icon {
            pointer-events: none;
            // border-radius: 50%;
            width: 25px;
            margin-top: 3px;
            svg {
              width: 15px;
              height: 15px;
              path {
                fill: rgba($outlaw-dark, 0.25);
              }
            }
          }

          .action-time {
            display: flex;
            flex-direction: column;
            flex: 4;
            .item-label {
              font-weight: 400;
              font-size: 1.3rem;
              color: $black80;
            }
          }
        }

        &:last-child .activity:last-child {
          border-bottom: none;
          padding-bottom: 0;
        }
      }
    }
  }

  &.elements {
    .apply-template {
      padding: 10px 15px;
      border-bottom: 1px solid $black10;
      background-color: $white;

      .info {
        display: flex;
        align-items: center;
        margin-bottom: 3px;
        .related {
          @extend .font_size_xs;
          font-weight: 600;
          margin-right: 5px;
        }
        svg {
          margin-top: -2px;
          path {
            fill: $black20;
          }
        }

        .spacer {
          flex: 1;
        }
        button {
          padding: 0;
          margin: 0;
          text-decoration: underline;
        }
      }
      .what-it-does {
        @extend .font_size_xs;
        padding: 5px 0;
      }
      .confirm {
        .warning {
          @extend .font_size_xs;
          color: $alert;
          padding: 10px 0;
        }
        button:first-child {
          margin-right: 5px;
        }
        padding-bottom: 10px;
        border-bottom: 1px solid $black10;
      }
    }

    .element {
      display: flex;
      flex-direction: column;
      position: relative;

      &:first-child {
        padding-top: 15px;
      }
      &:last-child {
        padding-bottom: 20px;
        border-bottom: 1px solid $black10;
      }
      .item-label {
        margin-bottom: 5px;
        font-size: 1.3rem;
        color: $black80;
        font-weight: 600;
      }

      .variable-view {
        &.manual-review {
          .form-group {
            margin-bottom: 0px !important;
          }
        }
        //override VariableView styles
        button,
        input,
        textarea {
          // opacity: .35;
          margin: 0;
          &:active,
          &:focus {
            opacity: 1;
          }
          &[disabled] {
            opacity: 0.35;
          }
        }
        button[disabled] {
          background-color: $black10;
        }
        .today-link {
          position: relative;
          float: right;
          margin-top: 0px;
        }
      }
    }
  }

  &.users {
    .guest-signing {
      display: flex;
      text-align: left;
      border-bottom: 1px solid $black10;
      padding: 5px 15px;
      align-items: center;
      background: $white;
      label {
        font-size: 1.3rem;
        font-weight: 600;
        color: $black80;
      }

      //ES: dunno why this needs an override but it drives me fucking crazy
      .checkbox {
        width: 100%;
      }

      .#{$dmp-checkbox} {
        flex: 1;
      }
    }

    .filter-bar {
      padding: 0 15px;
      display: flex;
      align-items: center;
      min-height: $panel-header-height;

      .user-count,
      .party-count {
        flex: 1;
        font-size: 1.3rem;
        font-weight: 600;
      }

      .add-user-actions {
        width: auto;
      }
    }

    .adding {
      padding: 10px 15px;

      .teammate-selector {
        width: 100%;
        .scroll {
          max-height: 200px;
          .teammates-list {
            max-height: 200px;
          }
        }
      }

      &.direct .dmp-btn-primary {
        margin-left: 10px;
      }
    }

    .deal-panel-item-disabled {
      cursor: not-allowed;
      .item-label,
      .title-org,
      .status {
        color: $black40;
      }
    }
    .dmp-alert a {
      font-weight: normal;
    }
  }

  &.ai-blocks {
    .deal-panel-item {
      .generate-action,
      .button-group {
        display: flex;
        justify-content: flex-end;

        .dmp-loader {
          margin-right: auto;
          .dmp-loader-spinner {
            margin-top: 7.5px;
          }
        }

        .auto-saved {
          margin-top: 7.5px;
          margin-right: auto;
          font-size: 12px;
          font-weight: 400;
          line-height: 15px;
          letter-spacing: 0px;
          text-align: left;
          color: #67a0f8;
        }
      }
      .dmp-alert {
        margin-bottom: 0px;
      }

      .results-container {
        .result {
          border: 1px solid $gray-3;
          border-radius: 5px;
          padding: 10px;
          margin-bottom: 10px;
          height: 85px;
          overflow: hidden;
          color: #2b3542;
          font-size: 12px;
          font-weight: 400;
          line-height: 15px;
          letter-spacing: 0px;
          cursor: pointer;
          transition-property: border;
          transition-duration: 0.25s;

          &:hover {
            border: 1px solid #8c98a5;
          }

          &.showMore {
            height: auto;
          }

          &.selected {
            border: 1px solid #854ad3;
            background-color: #f9f6fd;
          }

          a {
            font-size: 12px;
            font-weight: 600;
            line-height: 15px;
            text-decoration: underline;
          }
        }
      }
    }
  }

  &.readyCheck {
    .deal-panel-item {
      padding-top: 15px;
      padding-bottom: 15px;
    }

    .new-rc,
    .active-rc {
      .rc-title {
        font-weight: 600;
        color: $black80;
      }
    }
    .new-rc .rc-title {
      display: flex;
      span {
        flex: 1;
      }
      a {
        color: $outlaw;
        text-decoration: underline;
        font-size: 1.2rem;
      }
    }

    .active-rc {
      position: relative;

      .rc-title {
        padding-bottom: 8px;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .name {
          flex: 1;
          font-size: 1.5rem;
          font-weight: 600;
        }
        .description {
          flex: 1;
          font-size: 1.2rem;
        }

        .statusTime.failed {
          font-size: 1rem;
          color: $color-red;
        }

        .statusTime.passed {
          font-size: 1rem;
          color: $color-green;
        }

        .statusTime.skipped {
          font-size: 1rem;
          color: $color-red;
        }

        .statusLabel {
          position: inherit;
          &:not(.inactive) {
            cursor: pointer;
          }
        }
      }
      //flat message for user looking at panel while a ReadyCheck is active but does not include current user
      &.masked {
        border-left-color: $black40;
        .rc-title {
          color: $black40;
        }
        .instructions {
          margin: 10px 0;
          font-size: 1.3rem;
        }
      }

      //can't seem to figure out why this popover is not properly positioning at "bottom" of the statusLabel
      //if we figure that out, this can probably be removed.
      // .popover { margin-top: 65px; }
      .popover {
        width: 200px;
        border-radius: 3px;
        .popover-content {
          padding: 0;
        }
      }
    }

    .col-headers {
      height: 34px;
      padding-bottom: 0;
      display: flex;
      align-items: center;
      font-size: 1.2rem;
      color: $black40;
      font-weight: bold;
      .name {
        flex: 4;
      }
      .status {
        text-align: right;
        flex: 1;
      }
    }

    .candidates-list {
      padding-bottom: 10px;
      border-bottom: 1px solid $black10;
      &.deal {
        margin-bottom: 5px;
      }
      &.team {
        border-bottom: 0;
        padding-bottom: 0;
      }
    }

    .candidate,
    .voter {
      padding: 5px 0;
      display: flex;
      align-items: center;
      min-width: 0;
      label {
        font-size: 1.3rem;
        // line-height: 1.3rem;
        font-weight: 600;
        margin-right: 6px;
        margin-bottom: 0;
        color: $black80;
      }
    }
    .candidate {
      .#{$dmp-checkbox} {
        display: flex;
        input {
          flex-shrink: 0;
        }
        .candidate-info {
          display: flex;
          flex-direction: column;

          .email {
            margin-top: -4px; //pull email up a bit
            font-weight: normal;
            color: $black60;
            font-size: 1.2rem;
          }
        }
      }
    }
    .voter {
      .vote {
        flex-shrink: 0;
      }
      .name-email {
        flex: 4;
        display: flex;
        align-items: center;
        min-width: 0;
        label {
          overflow-x: hidden;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .status {
        text-align: right;
        font-weight: 500;
        flex: 1;
        color: $black40;
        &.approve {
          color: $done;
        }
        &.reject {
          color: $alert;
        }
      }
    }

    .rc-settings {
      padding: 10px 20px;
      border-top: 1px solid $black10;
      border-bottom: 1px solid $black10;
    }

    .admin-actions {
      display: flex;
      align-items: center;
      background: $white;
      button {
        margin-bottom: 0;
      }

      .status {
        display: flex;
        flex: 1;
        font-size: 1.3rem;
        font-weight: 600;

        &.error {
          color: $alert;
        }
        &.ready {
          color: $done;
        }
        &.no-changes {
          color: $black40;
        }
      }
      .spacer {
        flex: 1;
      }

      .loader {
        position: unset !important;
        margin-right: 10px;
      }
    }

    .checkpoint-group-selector {
      display: inline-grid;
      width: 100%;
      font-size: 1.3rem;

      .checkpoint-group-dropdown {
        padding-bottom: 6px;
        .dropdown-toggle,
        ul {
          width: 100%;
        }
      }

      .spacer {
        flex: 1;
      }

      .admin-action {
        display: flex;
      }

      .radio-button-group {
        display: flex;
        flex-direction: row;
      }

      .radio + .radio {
        margin-top: 10px;
        margin-left: 10px;
      }

      .radio {
        accent-color: $gray-1;
      }
    }

    .checkpoint-participant {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: $white;
      button {
        margin-bottom: 0;
      }

      .thumbsup {
        stroke: $color-green;
      }

      .thumbsdown {
        stroke: $color-red;
      }

      .fill-red {
        fill: $color-red;
      }

      .fill-green {
        fill: $color-green;
      }

      .teammate {
        width: 100%;
        padding: 0;

        .initials {
          border-radius: 20%;
          line-height: 25px;
          flex-basis: 25px;
          width: 25px;
          height: 25px;
        }
      }

      .teammate {
        &.simple {
          cursor: default;
          margin: 6px 0px;
          &:hover {
            background: none;
            .initials {
              background: $black40;
            }
            .email {
              color: $black60;
            }
          }
        }
        .email {
          font-size: 1.3rem;
          line-height: 1.7rem;
          color: $black60;
        }
        .name {
          line-height: 1.7rem;
        }
      }

      .status {
        display: flex;
        flex: 1;
        font-size: 1.3rem;
        font-weight: 600;

        &.error {
          color: $alert;
        }
        &.ready {
          color: $done;
        }
        &.no-changes {
          color: $black40;
        }
      }
      .spacer {
        flex: 1;
      }

      .loader {
        position: unset !important;
        margin-right: 10px;
      }
    }

    .template-checkpoint {
      .header {
        display: flex;
        justify-content: space-between;
      }

      .title {
        margin-bottom: 5px;
        align-items: center;
        line-height: 1.6rem;
        .name {
          flex: 1;
          font-size: 1.5rem;
          font-weight: 600;
          color: $black80;
        }
        .description {
          flex: 1;
          font-size: 1.2rem;
        }

        .statusTime.skipped {
          font-size: 1rem;
          color: $color-red;
          font-weight: 700;
        }

        .statusTime.failed {
          font-size: 1rem;
          color: $color-red;
          font-weight: 700;
        }

        .statusTime.passed {
          font-size: 1rem;
          color: $color-green;
          font-weight: 700;
        }

        .statusLabel {
          position: inherit;
          &:not(.inactive) {
            cursor: pointer;
          }
        }
      }

      .skipped-label {
        margin-right: 1.1rem;
        color: $color-red;
      }
    }

    .past-rc-list {
      .past-title {
        font-size: 1.4rem;
        color: $black40;
        font-weight: 600;
        padding: 10px 15px 0px 15px;
      }
      .past-rc {
        border-bottom: 1px solid $black10;
        margin: 0 15px;
        padding: 10px 0;
        background-color: $black2;
        // &:nth-child(even) {background: $black1;} //make every second one a slightly diff color
        &:last-child {
          padding-bottom: 20px;
        }
        .title-status {
          display: flex;
          flex-direction: row;
          align-items: center;
          .rc-title {
            flex: 1;
            font-size: 1.2rem;
            color: $black40;
            font-weight: 600;
          }
          .rc-status {
            position: inherit;
          } //override statusLabel
          margin-bottom: 3px;
        }
        .details {
          display: flex;
          flex-direction: row;
          align-items: center;

          .votes {
            display: flex;
            flex: 1;
            flex-direction: row;
            align-items: center;
          }
          .timestamp {
            color: blue;
          }
        }
      }
    }
    //note, this is used in both the past RC list and in current
    //so needs to not be nested too deeply
    .vote {
      @include initialCircle($black20);

      &:hover {
        background-color: $black30;
      }
      &.voted-approve:after,
      &.voted-reject:after {
        content: '';
        width: 9px;
        height: 9px;
        border-radius: 50%;
        border: 1px solid $white;
        position: absolute;
        right: -2px;
        top: -2px;
      }
      &.voted-approve:after {
        background: $done;
      }
      &.voted-reject:after {
        background: $alert;
      }
    }
  }

  &.versions,
  &.connections,
  &.lens {
    .popover-content > .panel-body {
      display: flex;
      flex-direction: column;
    }
    .filter-bar {
      padding: 0 15px;
      display: flex;
      align-items: center;
      min-height: $panel-header-height;

      .version-count,
      .connection-count {
        flex: 1;
        @extend .font_size_xs;
        font-weight: 600;
      }
    }
    .versions-list,
    .connection-list {
      flex: 1;
      display: flex;
      flex-direction: column;

      .deal-panel-item {
        padding-left: 12px;
        display: flex;
      }
    }
  }

  &.attachments {
    .filter-bar {
      padding: 0 15px;
      display: flex;
      align-items: center;
      min-height: $panel-header-height;
      // KEVEN REMOVE
      .dropdown {
        flex: 1;
        padding-right: 20px;
        justify-content: space-between;

        @extend .font_size_xs;
        font-weight: 600;
        button {
          padding-left: 0;
          width: auto;
        }
      }
    }
  }
}

.bundle-view {
  cursor: default;

  &.dmp-mobile-nav {
    .contents {
      width: $bundle-sidebar-width;
    }
    .panel-title {
      font-size: 1.2rem;
      font-weight: 500;
    }
  }

  @include mediaquery-medium {
    position: fixed;
    min-width: $bundle-sidebar-width;
    width: $bundle-sidebar-width;

    margin-top: $top-bar-height-slim + $deal-header-height !important;
    bottom: 0;
    border-radius: 0;
    z-index: 999;
  }

  > .popover-content {
    @include mediaquery-medium {
      width: $bundle-sidebar-width;
      border: none;
    }
  }

  .deal-panel-item {
    .bundle-item-meta {
      font-size: 1.2rem;
      margin-bottom: -3px;
    }
    .bundle-item-title {
      display: flex;
      align-items: center;
      color: $black80;
      svg {
        margin-right: 3px;
        path {
          fill: $black80;
        }
      }
      span {
        padding-top: 2px;
      }
    }
  }
  .deal-panel-item-active {
    position: relative;
    span {
      font-weight: 600;
    }
    border-left: none;
    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: -1px;
      bottom: -1px;
      width: 4px;
      background: $outlaw;
    }

    .mark-reviewed {
      padding: 5px 0px;
    }
  }
  .deal-panel-item:not(.deal-panel-item-active) {
    &:hover {
      background: $gray-5;
      cursor: pointer;
    }
  }
}

.lens {
  .filter-bar.review-tabs {
    padding-left: 0;
    padding-right: 0;
    overflow: scroll;
    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }

    .tabs {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      height: 40px;
      border-bottom: 1px solid $black10;

      .dmp-btn[disabled].btn.dmp-btn.active {
        background: none !important;
        &::after {
          content: '';
          position: absolute;
          height: 3px;
          right: 0px;
          left: 0px;
          bottom: -2px;
          background: $gray-2 !important;
        }
      }

      .dmp-btn-link {
        font-weight: 600;
        font-size: 1.2rem;
        padding-left: 15px;
        padding-right: 15px;
        text-decoration: none !important;

        .needsReview {
          height: 8px;
          width: 8px;
          border-radius: 100%;
          background: $color-orange;
          position: absolute;
          right: 2px;
          top: 2px;
        }

        &.dmp-btn-large {
          font-size: 1.4rem;
        }

        &.active::after {
          content: '';
          position: absolute;
          height: 3px;
          right: 0px;
          left: 0px;
          bottom: -2px;
          background: $black100;
        }
      }
    }
  }

  .definition {
    .item-label {
      cursor: pointer;
      font-weight: 300 !important;
      display: flex;
      align-items: center;
      svg {
        margin-left: 3px;
        path {
          fill: $done;
        }
      }

      &:hover:not(.selected) {
        text-decoration: underline;
      }

      &.selected {
        font-weight: 500 !important;
      }
    }
    .item-sub {
      border: 1px solid $black10;
      border-radius: 2px;
      padding: 5px;
      font-style: italic;
      color: $black80 !important;
      margin-bottom: 5px;
    }
  }
}
